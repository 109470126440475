import { Anchor, Box, Grid, Grommet, ResponsiveContext, Text} from 'grommet';
import './app.css';

import Header from './components/header/header';
import SlideOne from './containers/slide-one/slide-one';
import SlideThree from './containers/slide-three/slide-three';
import SlideFour from './containers/slide-four/slide-four';
import {SlideTwo} from './containers/slide-two/slide-two';

const theme = {
    global: {
        font: {
            family: 'Noto Sans',
            size: '18px',
            height: '20px',
        },
        hover: {
            background: {color: '#2D3D50'}
        },
    },
    formField: {
        border: false
    },
    select: {
        background: '#E4E8EC',
        icons: {
            color: '#2D3D50'
        }
    }
};

export default () => (
  <Grommet background='#F6F9FC' full={true} theme={theme}>
    <Grid fill rows={["auto", "flex"]}>
        <Header />
        <ResponsiveContext.Consumer>
            { size => (
                <Box style={{scrollBehavior: 'smooth', overflowY: 'scroll', overflowX: 'hidden'}}>
                    <SlideOne className={size === 'small' ? 'slide' : 'slide slide--full'}/>
                    <SlideTwo className={size === 'small' ? 'slide' : 'slide slide--full'}/>
                    {/*<SlideThree className='slide'/>*/}
                    <SlideFour className={size === 'small' ? 'slide' : 'slide slide--full'}/>
                </Box>)
            }
        </ResponsiveContext.Consumer>
    </Grid>
  </Grommet>
);
