import { Box, Button, Grid, Heading, ResponsiveContext, Text } from "grommet";

export default (props: any) => {
    const getQuoteSize = (size: string): string => {
        let fontSize: string = 'medium';

        if (size === 'xsmall') {
            fontSize = '32px'
        }

        if (size === 'small') {
            fontSize = '38px'
        }

        if (size === 'medium') {
            fontSize = '74px'
        }

        if (size === 'large') {
            fontSize = '116px'
        }

        if (size === 'xlarge') {
            fontSize = '224px'
        }

        return fontSize;
    }

    return(
        <div className={props.className}>
            <ResponsiveContext.Consumer>
                { size => (
                        <Grid rows={['flex', 'auto']}
                            fill
                            areas={[
                                ['heading'],
                                ['cta']
                            ]}
                            pad={{top: 'xlarge'}}>
                            <Box gridArea="heading" pad={size === 'small' ? {bottom: 'xlarge', horizontal: 'xlarge'} : {horizontal: 'large'}}>
                                <Text size={getQuoteSize(size)} weight={'bold'} alignSelf="stretch" textAlign={'start'} style={{lineHeight:'1.2'}}>
                                    Colored wr<span style={{color: '#EA4F4F'}}>ap</span>pers for cho<span style={{color: '#C552D5'}}>c</span>olate eggs are one big deception. We want a un<span style={{color: '#FFE243'}}>iv</span>ers<span style={{color: '#18B2AC'}}>al c</span>olor legend for wrappers now.
                                </Text>
                            </Box>
                            <Box gridArea="cta" align="center" justify="center" direction={ size === 'small' ? 'column' : 'row'} gap={'medium'} pad={ size === 'small' ? undefined : {vertical: 'large'}}>
                                <Button size={ size === 'small' ? 'medium' : 'large'} primary label="Find an egg" href="#find-an-egg" />
                                <Button size={ size === 'small' ? 'medium' : 'large'} label='Sign the petition' href="https://community.sumofus.org/u/64bb14ed-3a82-461a-bf3e-e4641c3a3de7" target="_blank" />
                            </Box>
                        </Grid>
                    )
                }     
            </ResponsiveContext.Consumer>           
        </div>  
    );
};
