export enum Pattern {
    DOTS = "Dots",
    STRIPES = "Stripes",
    CHECKERS = "Checkers",
    MIXED = "Mixed",
    SOLID = "Solid"
}

export enum FillingGroup {
    RUNNY = "Runny",
    SMOOTH = "Smooth",
    CHUNCKY = "Chunky",
    SOLID = "Solid"
}

export type WrapperPattern = Pattern | FillingGroup;

export enum ColorGroup {
    BEIGE = "Beiges",
    BLACK = "Blacks",
    BLUE = "Blues",
    BROWN = "Browns",
    GOLD = "Golds",
    GREEN = "Greens",
    GREY = "Greys",
    ORANGE = "Oranges",
    PINK = "Pinks",
    PURPLE = "Purples",
    RED = "Reds",
    SILVER = "Silvers",
    YELLOW = "Yellows",
}

export enum FillingType {
    NONE = "No Filling",
    BOOZY = "Boozy",
    COFFEE = "Coffee",
    COOKIE = "Cookie",
    CRAZY = "Crazy",
    CREAMY = "Creamy",
    FRUITY = "Fruity",
    NUTTY = "Nutty"
}

export enum ChocolateType {
    MILK = "Milk",
    DARK = "Dark",
    WHITE = "White",
    WHITE_MILK = 'White/Milk',
}

export interface EggStyle {
    isCustom?: boolean;
    colorGroup?: ColorGroup[];
    wrapperColor: string;
    wrapperPattern: WrapperPattern;
    patternColor: string;
}

export interface Egg{
    id: number | null;
    store: string;
    brand: string;
    chocolateType: ChocolateType;
    filling: string;
    extraFilling: string;
    fillingType: FillingType;
    current: EggStyle;
    new: EggStyle;
    source: string;
}