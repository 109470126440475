import { Anchor, Box, Image, ResponsiveContext, Text } from "grommet";

export default (props: any) => {
  return(
    <ResponsiveContext.Consumer>
      { size => (
        <div className={props.className}>
          <Box justify='center'>
            <Image
              fit="cover"
              alignSelf={size === 'small' || size === 'medium' ? undefined : 'center'}
              src="/assets/images/eggsplanation_leggend.png"
              style={{maxWidth: '1280px'}}
            />
          </Box>
          <Box pad={{bottom: 'medium'}}>
            <Text size={'xsmall'} textAlign={'end'} margin={{top: 'medium', right: 'medium'}}>
              Made by <Anchor label={'Donderberg'} style={{textDecoration: 'underline'}} color='#2D3D50' href={'https://www.donderberg.be'} target={'_blank'}/> 🌩🗻 as a result of seasonal frustration © 2021 <Anchor label={'@eggsplanation'} style={{textDecoration: 'underline'}} color='#2D3D50' href={'https://twitter.com/Eggsplanation'} target={'_blank'}/> | <Anchor label={'Disclaimer'} style={{textDecoration: 'underline'}} color='#2D3D50' href={'/disclaimer.html'} target={'_blank'}/>
            </Text>
          </Box>
        </div>
      )}
    </ResponsiveContext.Consumer>
  );
}