import React from 'react';
import { Anchor, Box, Button, FormField, Grid, ResponsiveContext, Text } from "grommet";
import { ShareRounded } from 'grommet-icons';

import './slide-two.css';
import MultiSelect from "../../components/multi-select/multi-select";
import Eggs from "../../components/visualizations/eggs/eggs";
import { Filters } from '../../models/filters.model';
import { ColorGroup, Egg, Pattern } from '../../models/egg.model';

const brandOptions = [
    {
        value: "Albert Heijn",
        text: "Albert Heijn" 
    },
    {
        value: "Aldi",
        text: "Aldi"
    },
    {
        value: "Balance",
        text: "Balance"
    },
    {
        value: "Boni",
        text: "Boni" 
    },
    {
        value: "Carrefour",
        text: "Carrefour"
    },
    {
        value: "Chocosweet",
        text: "Chocosweet"
    },
    {
        value: "Choklabar",
        text: "Choklabar"
    },
    {
        value: "Corné",
        text: "Corné"
    },
    {
        value: "Delicata",
        text: "Delicata" 
    },
    {
        value: "Everyday",
        text: "Everyday"
    },
    {
        value: "Favorina",
        text: "Favorina" 
    },
    {
        value: "Galler",
        text: "Galler" 
    },
    {
        value: "HEMA",
        text: "HEMA"    
    },
    {
        value: "Kruidvat",
        text: "Kruidvat"    
    },
    {
        value: "Leonidas",
        text: "Leonidas" 
    },
    {
        value: "Milka",
        text: "Milka" 
    },
    {
        value: "Neuhaus",
        text: "Neuhaus" 
    },
    {
        value: "Tony Chocolonely",
        text: "Tony Chocolonely"
    },
    {
        value: "Wiloco",
        text: "Wiloco"
    }
];

const colorOptions = [
    {
        value: ColorGroup.BEIGE,
        text: ColorGroup.BEIGE
    },
    {
        value: ColorGroup.BLACK,
        text: ColorGroup.BLACK
    },
    {
        value: ColorGroup.BLUE,
        text: ColorGroup.BLUE
    },
    {
        value: ColorGroup.BROWN,
        text: ColorGroup.BROWN
    },
    {
      value: ColorGroup.GOLD,
      text: ColorGroup.GOLD
    },
    {
      value: ColorGroup.GREEN,
      text: ColorGroup.GREEN
    },
    {
        value: ColorGroup.GREY,
        text: ColorGroup.GREY
    },
    {
        value: ColorGroup.ORANGE,
        text: ColorGroup.ORANGE
    },
    {
        value: ColorGroup.PINK,
        text: ColorGroup.PINK
    },
    {
        value: ColorGroup.PURPLE,
        text: ColorGroup.PURPLE
    },
    {
        value: ColorGroup.RED,
        text: ColorGroup.RED
    },
    {
        value: ColorGroup.SILVER,
        text: ColorGroup.SILVER
    },
    {
      value: ColorGroup.YELLOW,
      text: ColorGroup.YELLOW
    }
];

const patternOptions = [
    {
        value: Pattern.CHECKERS,
        text: Pattern.CHECKERS
    },
    {
        value: Pattern.DOTS,
        text: Pattern.DOTS
    },
    {
        value: Pattern.STRIPES,
        text: Pattern.STRIPES
    },
    {
        value: Pattern.MIXED,
        text: Pattern.MIXED
    },
    {
        value: Pattern.SOLID,
        text: "No Pattern"
    }

];

interface Props {
    className: any
}

interface State {
    filters: Filters;
    selectedEgg: Egg | null;
}

export class SlideTwo extends React.Component<Props, State> {
    private initialState = {
        filters: {
            brand: [],
            colorGroup: [],
            wrapperPattern: []
        },
        selectedEgg: null
    };

    constructor(props: Props) {
        super(props);
      
        this.state = this.initialState;
    }

    render() {
        return <div id='find-an-egg' className={this.props.className}>
            <ResponsiveContext.Consumer>
                { size => (
                    <Grid
                    columns={ size === 'small' ? ['flex'] : ['5fr', '10fr', '3fr']}
                    rows={ size === 'small' ?
                        ['flex'] : ['flex', 'xsmall', 'xsmall']
                    }
                    fill
                    areas={ size === 'small' ?
                        [
                            ['left'],
                            ['cloud'],
                            ['quote'],
                            ['cta']
                        ] : [
                            ['left', 'cloud', 'right'],
                            ['quote', 'quote', 'quote'],
                            ['cta', 'cta', 'cta']
                        ]
                    }>
                        <Box gridArea="left" justify='center'>
                            <Box className={size !== 'small' && this.state.selectedEgg ? this.state.selectedEgg.id ? 'details details--hide' : 'details' : 'details'} justify='evenly' pad={ size === 'small' ? {top: 'xlarge', bottom: 'large', horizontal: 'xlarge'} : {left: 'xlarge', vertical: 'xlarge'}}>
                                <Box>
                                    <FormField label="Brand*" name="select">
                                        <MultiSelect value={this.state.filters.brand} labelKey="Brand*" controlKey='brand' options={brandOptions} onFiltersChange={this.setFiltersState} />
                                    </FormField>
                                </Box>
                                <Box>
                                    <FormField label="Main Color" name="select">
                                        <MultiSelect value={this.state.filters.colorGroup} labelKey="Main Color" controlKey='colorGroup' options={colorOptions} onFiltersChange={this.setFiltersState} />
                                    </FormField>
                                </Box>
                                <Box>
                                    <FormField label="Pattern" name="select">
                                        <MultiSelect value={this.state.filters.wrapperPattern} labelKey="Pattern" controlKey='wrapperPattern' options={patternOptions} onFiltersChange={this.setFiltersState} />
                                    </FormField>
                                </Box>
                                <Box>
                                    <Text size="xsmall"><em>* In this phase, only brands available in Belgian stores are taken into account. Read more about our <Anchor style={{textDecoration: 'underline'}} label="data collection methodology" href=" https://docs.google.com/document/d/19P4fJwWgTNKgFbiO1QveWRF846oJdKUjMfV7caNKvzE" target="_blank" color='#2D3D50' />.</em></Text>    
                                </Box>
                            </Box>
                            <Box className={size !== 'small' && this.state.selectedEgg ? this.state.selectedEgg.id ? 'details' : 'details details--hide' : 'details details--fade-out'}>
                                <Text alignSelf='center' size="medium">Not the egg you're looking for?</Text>
                                <Button primary alignSelf='center' margin={{top: 'small'}} label="Report another egg" href="https://aw6htrdepj8.typeform.com/to/moc750nX" target="_blank" />
                            </Box>
                        </Box>
                        <Box className="eggs-container" gridArea="cloud" direction={size === 'small' ? 'column' : 'row'} justify='center' margin={ size === 'small' ? {'top': 'small', 'bottom': 'large'} : {'top': 'medium', 'bottom': 'medium'}} pad={ size === 'small' ? undefined : {vertical: 'xlarge'}}>
                            <Text size='xlarge' style={size === 'small' ? {left: '12%', top: '34%'} : {left: '34%', top: '27%'}} className={this.state.selectedEgg ? this.state.selectedEgg.id ? 'back' : 'back back--hide' : 'back back--fade-out'} onClick={() => this.setSelectedEgg({id: 0} as Egg)}>
                                ⟵
                            </Text>
                            <Eggs selectedEgg={this.state.selectedEgg} filters={this.state.filters} onFiltersReset={this.resetFiltersState} onSelectEgg={this.setSelectedEgg}/>
                            <Grid className={this.state.selectedEgg ? this.state.selectedEgg.id ? 'details' : 'details details--hide' : 'details details--fade-out'}
                                pad={ size === 'small' ? {top: 'large', bottom: 'small'} : {left: 'medium'}}
                                gridArea="details"
                                alignContent='center'
                                areas={[
                                    ['store'],
                                    ['chocolate'],
                                    ['filling'],
                                    ['extra']
                                    ]}
                                fill={false}>   
                                <Box gridArea="store">
                                    <label style={size === 'small' ? {textAlign: 'center'} : undefined}>Brand</label>
                                    <Box direction='row' alignContent={'center'} justify={size === 'small' ? 'center' : 'start'}>
                                        <Text weight='bold' alignSelf='center'>{this.state.selectedEgg?.brand}</Text> 
                                        {this.state.selectedEgg?.source && <Anchor alignSelf='center' icon={<ShareRounded size='18px' color="brand" />} href={this.state.selectedEgg?.source} target="_blank" />}
                                    </Box>
                                </Box>
                                <Box gridArea="chocolate" pad={size === 'small' ? {top: 'medium'} : {top: 'medium'}}>
                                    <label style={size === 'small' ? {textAlign: 'center'} : undefined}>Chocolate</label>
                                    <Text textAlign={size === 'small' ? 'center' : 'start'} weight='bold'>{this.state.selectedEgg?.chocolateType}</Text>
                                </Box>
                                <Box gridArea="filling" pad={size === 'small' ? {top: 'medium'} : {top: 'medium'}}>
                                    <label style={size === 'small' ? {textAlign: 'center'} : undefined}>Filling</label>
                                    <Text textAlign={size === 'small' ? 'center' : 'start'} weight='bold'>{this.state.selectedEgg?.filling}</Text>
                                </Box>
                                {this.state.selectedEgg?.extraFilling && <Box pad={size === 'small' ? {top: 'medium'} : {top: 'medium'}} gridArea="extra">
                                    <label style={size === 'small' ? {textAlign: 'center'} : undefined}>Extra</label>
                                    <Text textAlign={size === 'small' ? 'center' : 'start'} weight='bold'>{this.state.selectedEgg?.extraFilling}</Text>
                                </Box>}
                            </Grid>
                            <Box className={size === 'small' && this.state.selectedEgg ? this.state.selectedEgg.id ? 'details' : 'details details--hide' : 'details details--fade-out'} pad={{top: 'medium'}}>
                                <Text alignSelf='center' size="medium">Not the egg you're looking for?</Text>
                                <Button primary alignSelf='center' margin={{top: 'small'}} label="Report another egg" href="https://aw6htrdepj8.typeform.com/to/moc750nX" target="_blank" />
                            </Box>
                        </Box>
                        <Box gridArea="quote" justify='center' background='#ffffff' pad={{vertical: 'medium'}}>
                            <Text textAlign={'center'} alignSelf='center' size={ size === 'small' ? "small" : "large"} margin={ size === 'small' ? {horizontal: 'large'} : undefined}>Still surprised by the flavor? There could be a slight margin of error...</Text>
                            <Text textAlign={'center'} alignSelf='center' size={ size === 'small' ? "small" : "large"} margin={ size === 'small' ? {horizontal: 'large'} : undefined} weight='bold'>That’s why we need a universal color legend for chocolate easter eggs!</Text>
                        </Box>
                        <Box gridArea="cta" justify='center' pad={{vertical: 'medium'}}>
                            <Text textAlign='center' alignSelf='center' size={ size === 'small' ? "medium" : "large"} margin={ size === 'small' ? {horizontal: 'medium'} : undefined}>Our legend explained ↓</Text>
                        </Box>
                    </Grid>)
                }
            </ResponsiveContext.Consumer>
        </div>;
    }

    private setFiltersState = (filters: {}) => {
        let newState: State = {
                ...this.state,
                filters: {
                    ...this.state.filters, 
                    ...filters}
                }
            ;

        if (this.state.selectedEgg) {
            newState = {
                ...newState,
                selectedEgg: {id: null} as Egg
            }
        }

        this.setState(newState);
    };

    private resetFiltersState = () => {
        this.setState({filters: this.initialState.filters})
    }

    private setSelectedEgg = (egg: Egg | null) => this.setState({selectedEgg: egg});
};