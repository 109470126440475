import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import textures from 'textures';

import './eggs.css';
import { Filters, SelectOption } from '../../../models/filters.model';
import { Pattern, Egg, EggStyle, FillingGroup, ColorGroup, WrapperPattern, FillingType, ChocolateType } from '../../../models/egg.model';
import { Box, Button, ResponsiveContext, Text } from 'grommet';
import { SplitBy } from '../../../models/splitBy.model';

// Define margin and dimensions
const margin = {left: 100, top: 30, right: 20, bottom: 20};
var w = 600 - margin.left - margin.right; //2400 | 800
var h = 450 - margin.top - margin.bottom; //1350 | 600

// Define egg shape
var eiVorm = 'M204.3,409c-93.8,0-156.8-71.2-156.8-177.2C47.5,121.3,129.6,0,204.3,0s156.8,121.3,156.8,231.8 C361.1,337.8,298.1,409,204.3,409z';

// Data Helper functions
var noColor = (style: EggStyle) => style.wrapperPattern === Pattern.SOLID && (style.wrapperColor === '#FAFAFB' || style.wrapperColor === '#EEEEEE');

// Define Patterns
var stripesPattern = (style: EggStyle) => textures.lines().orientation('diagonal').size(120).strokeWidth(40).stroke(style.patternColor).background(style.wrapperColor);
var dotsPattern = (style: EggStyle) => textures.circles().complement().size(120).radius(30).fill(style.patternColor).background(style.wrapperColor);
var checkersPattern = (style: EggStyle) => textures.lines().orientation('vertical', 'horizontal').size(102).strokeWidth(20).stroke(style.patternColor).background(style.wrapperColor);
var mixedPattern = (style: EggStyle) => textures.paths().d('crosses').size(102).strokeWidth(20).stroke(style.patternColor).background(style.wrapperColor);

// Current or Leggend?
const getStyle = (egg: Egg, legend: boolean) => legend ? egg.new : egg.current;

// filling and chocolate types
const fillingTypes = [FillingType.NONE, FillingType.NUTTY, FillingType.CREAMY, FillingType.FRUITY, FillingType.COFFEE, FillingType.COOKIE, FillingType.BOOZY, FillingType.CRAZY] // const fillingTypes: any = [...new Set(data.map((d: Egg) => d.fillingType))];
const chocolateTypes = [ChocolateType.MILK, ChocolateType.WHITE, ChocolateType.DARK, ChocolateType.WHITE_MILK] // const chocolateTypes: any = [...new Set(data.map((d: Egg) => d.chocolateType))];

// Forces
const fillingScale: any = d3.scalePoint()
    .range([margin.left, w - margin.right])
    .domain(fillingTypes)
    .padding(0.5);

const chocolateScale: any = d3.scalePoint()
    .range([h - margin.bottom, margin.top * 4])
    .domain(chocolateTypes)
    .padding(0.5);

const chargeForce = d3.forceManyBody().strength();
const centerXForce = d3.forceX(w/2);
const centerYForce = d3.forceY(h/2);

const xFillForce = d3.forceX((d: any) => fillingScale(d.fillingType));
const yChocForce = d3.forceY((d: any) => chocolateScale(d.chocolateType));

const forceCollide = d3.forceCollide()
    .strength(1)
    .radius(12) //(d => countScale(d.Count) + 1)
    .iterations(2);

// Define the movement
let simulation = d3.forceSimulation()
        .force('charge', chargeForce as any)
        .force('collide', forceCollide)
        .force('x', centerXForce)
        .force('y', centerYForce);

// Categories for scale 
let chocolateCategories: ChocolateType[] = [];
let fillingCategories: FillingType[] = [];

const scale = (data: Egg[], area: any, scaleType: SplitBy = SplitBy.NONE) => {
    // set labels
    setLabels(area, scaleType);

    // eggNode
    let eggNode = area.selectAll('.node');
    eggNode = eggNode.data(data).join(
        (enter: any) => enter.append('path'),
        (update: any) => update,
        (exit: any) => exit.remove()
    );

    eggNode
        .on('mouseover', null)
        .on('mousemove', null)
        .on('mouseout', null)
        .on('click', null);
    
    // Define forces for placement
    simulation = updateSimulation(simulation, scaleType);

    // Define the movement
    simulation.nodes(data as d3.SimulationNodeDatum[]).on('tick', () => {
        eggNode
            .attr('cx', (d: any) => d.x)
            .attr('cy', (d: any) => d.y)
            .attr('transform', (d: any) => `translate(${d.x}, ${d.y}) scale(0.05 0.05)`);
    });

    simulation.alpha(1).restart();
}

// Define svg size and margins
const update = (data: Egg[], svg: any, area: any, tooltip: any, selectEgg: Function, isLegend: boolean = false) => {
    var eggNode = area.selectAll('.node');

    // eggNode
    eggNode = eggNode.data(data).join(
        (enter: any) => enter.append('path'),
        (update: any) => update,
        (exit: any) => exit.remove()
    );
        
    eggNode
        .attr('class', 'node')
        .attr('d', eiVorm)
        .attr('fill', (d: Egg) => {
            const style = getStyle(d, isLegend);

            if (style.isCustom || style.wrapperColor === 'Double') {
                const patternId = style.wrapperColor === 'Double' ? 'double' : d.id;

                return `url(#pattern-${patternId})`
            } else {
                switch (style.wrapperPattern) {
                    case Pattern.DOTS:
                    case FillingGroup.RUNNY:
                    const circles = dotsPattern(style);
                    svg.call(circles);
                    return circles.url();
                    break;
                    case Pattern.STRIPES:
                    case FillingGroup.SMOOTH:
                    const stripes = stripesPattern(style);
                    svg.call(stripes);
                    return stripes.url();
                    break;
                    case Pattern.CHECKERS:
                    case FillingGroup.CHUNCKY:
                    const checkers = checkersPattern(style);
                    svg.call(checkers);
                    return checkers.url();
                    break;
                    case Pattern.MIXED:
                    const mixed = mixedPattern(style);
                    svg.call(mixed);
                    return mixed.url();
                    break;
                    case Pattern.SOLID:
                    return style.wrapperColor;
                    break;
                    default:
                    return 'pink'
                }
            }
        })
        .attr('stroke', (d: Egg) => {
            const style = getStyle(d, isLegend);

            if (noColor(style)) {
                return '#5B5B5B'
            }
        })
        .attr('stroke-width', (d: Egg) => {
            const style = getStyle(d, isLegend);

            if (noColor(style)) {
                return '3'
            }
        })
        .attr('cursor', 'pointer')
        .on('mouseover', (event: MouseEvent, d: Egg) => {
            const e = eggNode.nodes();
            const i = e.indexOf(event.currentTarget);
            
            eggNode
            .filter((_d: any, j: any) => i !== j)
            .transition()
            .duration(350)
            .attr('opacity', .2)
            
            tooltip.html(`${d.store ? '<strong>' + d.store + '</strong><br/>' : ''} ${d.chocolateType ? '<strong>' + d.chocolateType + '</strong> chocolate<br/>' : ''} ${d.filling && d.filling !== 'No Filling' ? '<strong>' + d.filling + '</strong> filling<br/>' : ''} ${d.filling && d.filling === 'No Filling' ? '<strong>' + d.filling + '</strong><br/>' : ''} ${d.extraFilling ? 'Extra: ' + d.extraFilling : ''}`)
            .style('left', (event.pageX) + 'px')
            .style('top', (event.pageY + 10) + 'px');
            
            tooltip.transition()
            .style('opacity', .95);    
        })
        .on('mousemove', (event: MouseEvent) => {
            tooltip
            .style('left', (event.pageX) + 'px')
            .style('top', (event.pageY + 10) + 'px');
        })
        .on('mouseout', () => {
            eggNode
            .transition()
            .duration(350)
            .attr('opacity', 1)
            
            tooltip.transition()
            .style('opacity', 0); 
        })
        .on('click', (event: MouseEvent, d: Egg) => selectEgg(d));

    // Define forces for placement
    simulation = updateSimulation(simulation);

    simulation.nodes(data as d3.SimulationNodeDatum[]).on('tick', () => {
        eggNode
            .attr('cx', (d: any) => d.x)
            .attr('cy', (d: any) => d.y)
            .attr('transform', (d: any) => `translate(${d.x}, ${d.y}) scale(0.05 0.05)`);
    });
        
    simulation.alpha(1).restart();
}

// Define svg size and margins
const singleOut = (data: Egg[], svg: any, area: any, tooltip: any, isLegend: boolean = false) => {
    var eggNode = area.selectAll('.node');

    // eggNode
    eggNode = eggNode.data(data).join(
        (enter: any) => enter.append('path'),
        (update: any) => update,
        (exit: any) => exit.remove()
    );
        
    eggNode
        .attr('class', 'node')
        .attr('d', eiVorm)
        .attr('fill', (d: Egg) => {
            const style = getStyle(d, isLegend);

            if (style.isCustom || style.wrapperColor === 'Double') {
                const patternId = style.wrapperColor === 'Double' ? 'double' : d.id;

                return `url(#pattern-${patternId})`
            } else {
                switch (style.wrapperPattern) {
                    case Pattern.DOTS:
                    case FillingGroup.RUNNY:
                    const circles = dotsPattern(style);
                    svg.call(circles);
                    return circles.url();
                    break;
                    case Pattern.STRIPES:
                    case FillingGroup.SMOOTH:
                    const stripes = stripesPattern(style);
                    svg.call(stripes);
                    return stripes.url();
                    break;
                    case Pattern.CHECKERS:
                    case FillingGroup.CHUNCKY:
                    const checkers = checkersPattern(style);
                    svg.call(checkers);
                    return checkers.url();
                    break;
                    case Pattern.MIXED:
                    const mixed = mixedPattern(style);
                    svg.call(mixed);
                    return mixed.url();
                    break;
                    case Pattern.SOLID:
                    return style.wrapperColor;
                    break;
                    default:
                    return 'pink'
                }
            }
        })
        .attr('stroke', (d: Egg) => {
            const style = getStyle(d, isLegend);

            if (noColor(style)) {
                return '#5B5B5B'
            }
        })
        .attr('stroke-width', (d: Egg) => {
            const style = getStyle(d, isLegend);

            if (noColor(style)) {
                return '3'
            }
        })
        .attr('cursor', 'default')
        .on('mouseover', null)
        .on('mousemove', null)
        .on('mouseout', null)
        .on('click', null)
        .transition()
        .duration(350)
        .attr('opacity', 1);

    tooltip.transition()
        .style('opacity', 0);
        
    // Define forces for placement
    simulation = updateSimulation(simulation);
        
    simulation.nodes(data as d3.SimulationNodeDatum[]).on('tick', () => {
        eggNode
            .attr('cx', (d: any) => d.x)
            .attr('cy', (d: any) => d.y)
            .attr('transform', (d: any) => `translate(${d.x / 2 * 0.8}, ${d.y / 2 * 0.8}) scale(0.70 0.70)`);
    });
    
    simulation.alpha(1).restart();
}

const updateSimulation = (simulation: d3.Simulation<d3.SimulationNodeDatum, undefined>, scaleType: SplitBy = SplitBy.NONE) => {
    if (scaleType === SplitBy.NONE) {
        simulation
            .force('x', centerXForce)
            .force('y', centerYForce);
    }
    
    if (scaleType === SplitBy.CHOCOLATE) {
        simulation.force('y', yChocForce);
    }
    
    if (scaleType === SplitBy.FILLING) {
        simulation.force('x', xFillForce);
    }

    return simulation;
};

const setLabels = (area: any, scaleType: SplitBy = SplitBy.NONE) => {
    if (scaleType === SplitBy.CHOCOLATE) {
        chocolateCategories = chocolateTypes;
    } 
    
    if (scaleType === SplitBy.FILLING) {
        fillingCategories = fillingTypes;
    }

    if (scaleType === SplitBy.NONE) {
        chocolateCategories = [];
        fillingCategories = [];
    }

    // fillingLabels
    let fillingLabels = area.selectAll('text.fillingLabels');
    let updatedFillingLabels = fillingLabels.data(fillingCategories);

    fillingLabels = updatedFillingLabels.join(
        (enter: any) => enter.append('text')
                                .attr('class', 'fillingLabels')
                                .text((d: any) => d)
                                .attr('x', (d: any) => fillingScale(d))
                                .attr('y', margin.top * 3)
                                .attr('text-anchor', 'middle')
                                .attr('fill', "white")
                                .transition()
                                .duration(500)
                                .attr('fill', "black")
                                .selection().merge(updatedFillingLabels),
        (update: any) => update,
        (exit: any) => exit.transition()
                        .duration(500)
                        .attr('fill', 'white')
                        .remove()
    );      
    
    // chocolateLabels
    let chocolateLabels = area.selectAll('text.chocolateLabels');
    let updatedChocolateLabels = chocolateLabels.data(chocolateCategories);

    chocolateLabels = updatedChocolateLabels.join(
        (enter: any) => enter.append('text')
                                .attr('class', 'chocolateLabels')
                                .text((d: any) => d)
                                .attr('x', margin.left / 2)
                                .attr('y', (d: any) => chocolateScale(d))
                                .attr('text-anchor', 'middle')
                                .attr('fill', "white")
                                .transition()
                                .duration(500)
                                .attr('fill', "black")
                                .selection().merge(updatedChocolateLabels),
        (update: any) => update,
        (exit: any) => exit.transition()
                        .duration(500)
                        .attr('fill', 'white')
                        .remove()
    );    
}

const addCustomPatterns = (svg: any, data: Egg[]) => {
    const defs = svg.append('svg:defs');

    data.map((customEgg: Egg) => 
        defs.append('svg:pattern')
            .attr('id', `pattern-${customEgg.id}`)
            .attr('width', 319)
            .attr('height', 412.5)
            .attr("x", 45)
            .attr("y", 0)
            .attr('patternUnits', 'userSpaceOnUse')
            .append('svg:image')
            .attr('xlink:href', `assets/images/${customEgg.id}.svg`)
            .attr("width", 319)
            .attr("height", 412.5)
    );

    defs.append('svg:pattern')
            .attr('id', `pattern-double`)
            .attr('width', 319)
            .attr('height', 412.5)
            .attr("x", 45)
            .attr("y", 0)
            .attr('patternUnits', 'userSpaceOnUse')
            .append('svg:image')
            .attr('xlink:href', `assets/images/double.svg`)
            .attr("width", 319)
            .attr("height", 412.5)
};

const getDataAndUpdate = async (svg: any, area: any, tooltip:any, selectEgg: Function) => {
    let data: any = await d3.json(`assets/data/eggsplanation_data_2021.json?${new Date().getTime()}`);
    const customData = data.filter((egg: Egg) => egg.current.isCustom);

    addCustomPatterns(svg, customData);

    update(data, svg, area, tooltip, selectEgg);

    return data;
};

const filterDataAndUpdate = (data: Egg[], filters: Filters, svg:any, area: any, tooltip:any, selectEgg: Function): Egg[] => {
    let newData: Egg[] = data;
    
    if (filters.brand.length || filters.colorGroup.length || filters.wrapperPattern.length || filters.fillingType?.length) {
        const brandFilters = filters.brand?.length ? filters.brand.map((option: SelectOption<string>) => option.value) : null;
        const colorFilters = filters.colorGroup?.length ? filters.colorGroup.map((option: SelectOption<ColorGroup>) => option.value) : null;
        const patternFilters = filters.wrapperPattern?.length ? filters.wrapperPattern.map((option: SelectOption<WrapperPattern>) => option.value) : null;
        const fillingTypeFilters = filters.fillingType?.length ? filters.fillingType.map((option: SelectOption<string>) => option.value) : null;

        newData = data.filter((item: Egg) => {
            return (brandFilters ? brandFilters.includes(item.brand) : true) &&
                (colorFilters ? item.current.colorGroup?.some((colorGroup: ColorGroup) => colorFilters.includes(colorGroup)) : true) &&
                (patternFilters ? patternFilters.includes(item.current.wrapperPattern) : true) &&
                (fillingTypeFilters ? (fillingTypeFilters).includes(item.fillingType) : true)
        });
    }
    
    update(newData, svg, area, tooltip, selectEgg);

    return newData;
}


interface Props {
    filters: Filters;
    selectedEgg: Egg | null;
    onFiltersReset: Function;
    onSelectEgg: Function;
    splitBy?: SplitBy | null;
}

interface State {
    data: Egg[] | null;
    newData: Egg[] | null;
}

export default (props: Props) => {
    const initialState = {data: null, newData: null, selectedEgg: null};
    const [state, setState] = useState<State>(initialState);
    const svgRef = useRef(null);
    const tooltipRef = useRef(null);
    const ref = useRef(null);

    const selectEgg = (egg: Egg) => props.onSelectEgg(egg);

    useEffect(() => {
        // Define svg area
        const svg = d3.select(svgRef.current);
        const g = d3.select(ref.current);
        const tooltip = d3.select(tooltipRef.current);

        if (state.data) {
            const newData = filterDataAndUpdate(state.data, props.filters, svg, g, tooltip, selectEgg);
            setState((prevState) => ({...prevState, ...{newData: newData}}));
        } else {
            getDataAndUpdate(svg, g, tooltip, selectEgg).then((data) => setState((prevState) => ({...prevState, ...{data: data}})));
        }
    }, [props.filters]);

    useEffect(() => {        
        if (state.data && props.selectedEgg?.id !== null) {
            // Define svg area
            const svg = d3.select(svgRef.current);
            const g = d3.select(ref.current);
            const tooltip = d3.select(tooltipRef.current);

            if (props.selectedEgg && props.selectedEgg.id !== 0) {
                singleOut([props.selectedEgg], svg, g, tooltip)
            } else {
                update(state.newData? state.newData : state.data, svg, g, tooltip, selectEgg)
            }
        }
    }, [props.selectedEgg]);

    useEffect(() => {        
        if (state.data && props.splitBy) {
            // Define svg area
            const g = d3.select(ref.current);

            scale(state.newData?.length ? state.newData : state.data as Egg[], g, props.splitBy)
        }
    }, [props.splitBy]);

    return (
        <ResponsiveContext.Consumer>
            { size => (
                <>
                    <div className={!(state.newData) || (state.newData && state.newData.length) ? 'chart' : 'chart chart--no-results'}>
                        <div ref={tooltipRef} className='tooltip' style={size === 'small' ? {display: 'none'} : {opacity: 0, textAlign: 'left'}}></div>
                        <svg ref={svgRef} viewBox={`0 0 ${w} ${h}`}>
                            <g ref={ref}/>
                        </svg>
                    </div>
                    {state.newData && !(state.newData.length) && <Box alignSelf='center' style={{fontStyle: 'italic'}} pad={size === 'small' ? {vertical: 'large', horizontal: 'large'} : undefined}>
                        <Text textAlign='center' margin={{bottom: 'small'}}>This filter combination does not generate results.</Text>
                        <Box direction={'row'} justify={'center'}>
                            <Button primary className='border' color={'#2D3D50'} fill={false} alignSelf={'start'} label="Reset" onClick={() => {props.onFiltersReset();}} />
                            <Text margin={{left: 'xsmall'}} alignSelf={'center'}>or adjust the filters.</Text>
                        </Box>
                    </Box>}
                </>
            )}
        </ResponsiveContext.Consumer>
    );
}
