import { Box, Button, Select, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import { SelectOption } from '../../models/filters.model';

import Option from './option/option';

interface Props {
    value: SelectOption<any>[],
    labelKey: string,
    controlKey: string,  
    options: {value: string, text: string}[], 
    onFiltersChange: Function
}

export default ({ value, labelKey, controlKey, options, onFiltersChange}: Props) => {
  const onRemoveOption = (option: SelectOption<any>) => {
    const optionIndex = options.indexOf(option);
    onFiltersChange({[controlKey]: value.filter((selectedOption: SelectOption<any>) => selectedOption !== option)});
  };

  const renderOption = (option: SelectOption<any>) => (
    <Button
      key={`option_tag_${option.value}`}
      href="#"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onRemoveOption(option);
      }}
      onFocus={event => event.stopPropagation()}
    >
      <Box
        align="center"
        direction="row"
        margin="xsmall"
        background={'#2D3D50'}
        round={'xsmall'}
      >
        <Text size="xsmall" margin={{ vertical: 'xsmall', horizontal:'small' }}>{option.text}</Text>
        <Box pad={{horizontal: 'small'}}>
          <FormClose size="medium" style={{ width: '12px', height: '12px' }} />
        </Box>
      </Box>
    </Button>
  );

  return (
    <Select
          multiple
          closeOnChange={false}
          placeholder="select an option..."
          options={options}
          dropHeight="medium"
          labelKey={labelKey}
          value={value}
          plain
          valueLabel={
            <Box wrap direction="row" width="large">
              {value && value.length ? (
                value.map((option: SelectOption<any>) => renderOption(option))
              ) : (
                <Box
                  pad={{ vertical: 'xsmall', horizontal: 'small' }}
                  margin="xsmall"
                >
                  Select Option
                </Box>
              )}
            </Box>
          }
          onChange={({ _, value: nextValue }) => {
            onFiltersChange({[controlKey]: nextValue});
          }}
        >
          {(option, _index, _options, state) => (
            <Option option={option} active={state.active} />
          )}
    </Select>
  );
};
