import { Anchor, Box, Button, Header, Menu, ResponsiveContext } from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';

export default () => (
    <Header style={{position: 'absolute', width: '100%', pointerEvents: 'none'}} justify="end" pad={{ top: 'medium', bottom: 'large', horizontal: 'large'}} background='transparent'>
      <ResponsiveContext.Consumer>
        {size =>
            size === 'small' ? (
                <Box justify="end" style={{zIndex: 1, pointerEvents: 'auto'}}>
                    <Menu
                        a11yTitle="Navigation Menu"
                        dropProps={{ align: { top: 'bottom', right: 'right' } }}
                        icon={<MenuIcon color="brand" />}
                        items={[
                        {
                            label: <Box pad="small">Find an Egg</Box>,
                            href: '#find-an-egg',
                        },
                        /*
                        {
                            label: <Box pad="small">Eggsplanation</Box>,
                            href: '#',
                        },
                        */
                        {
                            label: <Box pad="small">Report an egg</Box>,
                            href: 'https://aw6htrdepj8.typeform.com/to/moc750nX',
                            target: '_blank'
                        },
                        ]}
                    />
                </Box>
            ) : (
                <Box style={{zIndex: 1, pointerEvents: 'auto'}} align="center" justify="end" direction="row" gap="medium">
                    <Anchor weight={'normal'} label="Find an egg" href="#find-an-egg" color='#2D3D50' />
                    {/*<Anchor weight={'normal'} label="Eggsplanation" href="#" color='#2D3D50' />*/}
                    <Button primary label="Report an egg" href="https://aw6htrdepj8.typeform.com/to/moc750nX" target="_blank" />
                </Box>
            )
        }
      </ResponsiveContext.Consumer>
    </Header>
);